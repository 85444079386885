.fundraiser-iframe {
    width: 100%; /* Ensures the iframe takes up the full width of its container */
    height: 1350px; /* Default height */
    border: none; /* Removes any default border */
    max-width: 100%; /* Prevents overflow on mobile */
    display: block; /* Fixes inline-block spacing issues */
    margin: 0 auto;
}

@media (max-width: 800px) {
    .fundraiser-iframe {
      height: 2000px;
    }
}
  
  