.split-view {
    display: flex;
    background-color: rgb(236, 236, 236);
    align-items: center;
    justify-content: flex-start; 
  }

  .split-view img {
   height: auto;
  }
  
  .left-side,
  .right-side {
    width: 50%;
    padding: 3em;
    box-sizing: border-box;
  }

  
  @media screen and (max-width: 1200px) {
    .split-view {
      display: block;
    }

    .left-side,
    .right-side {
      width: 100%;
      padding: 1em 2em;
    }

    .left-side
     {
      padding-bottom: 0;
    }
  }   
  
  @media screen and (max-width: 1000px) {
    .left-side,
    .right-side {
      padding: 2em;
    }

  } 
  
  .inline-group span {
    white-space: nowrap;
  }


  @media screen and (max-width: 500px) {
    .left-side,
    .right-side {
      padding: 1em;
    }

  } 
  