.school-about h3 {
    margin-bottom: 0em;
}

.school-about p {
    margin-top: 0.7em;
}

.school-about u b {
    cursor: pointer;
}


@media screen and (max-width: 800px) {
    .school-about img {
        margin-bottom: 2.5em;
    }

}
  
  