#sponsor-form {
    margin-top: 0;
}

.sponsor-form p {
    font-size: 0.5em;
}

.sponsor-form button {
    padding: 10px 20px;
    width: 100%;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 20px;
    font-weight: bold;
}

.smallTxt {
    font-size: 0.7em;
}

.noMarginBottom {
    margin-bottom: 0;
}

.input-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    gap: 10px;
}

.input-container input, .input-container textarea {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
}