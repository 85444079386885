.economics-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.last-updated {
  font-size: 0.8em;
  margin: 0;
}


.economics-table-parent {
    overflow-x: auto;
    padding: 0.5em;
}

.economics-table {
    width: 100%;
    table-layout: fixed;
    border-collapse: collapse;
    user-select: none;
    border: 1px solid #ccc;
  }

  .economics-table th {
    background-color: #E6F5FD;
    border-bottom: 0.1em solid #ccc;
  }
  
  .economics-table th,
  .economics-table td {
    /* border: 1px solid #ccc; */
    padding: 10px;
    text-align: center;
    white-space: nowrap; /* Prevent text wrapping */
    overflow: hidden; /* Hide overflowed text */
    text-overflow: ellipsis; /* Show "..." when the text overflows */
    position: relative;
  }


  .economics-table td:hover,
  .economics-table th:hover {
    overflow: visible;
  }

  .economics-table th:last-child,
  .economics-table td:last-child {
    width: 300px; /* Assign double width to the last column */
  }
  
  .economics-table th:not(:last-child),
  .economics-table td:not(:last-child) {
    width: 100px; /* Standard width for all other columns */
  }

  .economics-table th:nth-child(1) {
    text-align: left;
    width: 120px;
  }
  
  .economics-table th:nth-child(2),
  .economics-table td:nth-child(2){
    text-align: left;
  }
  
  
  .economics-table tr:hover {
    background-color: #e6f7ff;
  }
  
  .economics-table td:first-child,  
  .economics-table th:last-child, 
  .economics-table td:last-child  {
    text-align: left;
  }

  .admin-comment-input {
    width: 100%;
    background-color: inherit;
    border-bottom: 0.15em solid #ccc;
  }

  .comments-row {
    font-size: 0.9em;
  }

  .budget-request-comment-input {
    width: 500px;
  }
  
  .approval-actions {
    display: flex;
    align-items: center;
    gap: 1em;
  }
  
  .approval-input {
    width: 100px; /* Adjust width as needed */
    padding: 0.5em;
    border: 1px solid #ccc;
    border-radius: 0.3em;
    font-size: 1em;
  }
  
  .approval-buttons {
    display: flex;
    gap: 0.5em;
  }
  
  .approval-button {
    padding: 0.5em 1em;
    border: none;
    border-radius: 4px;
    font-size: 0.8em;
    cursor: pointer;
    transition: background-color 0.2s ease;
  }
  
  .approval-button.partial-approve {
    background-color: #f0ad4e;
    color: white;
  }
  
  .approval-button.approve {
    background-color: #5cb85c;
    color: white;
  }
  
  .approval-button.decline {
    background-color: #d9534f;
    color: white;
  }

  .economics-table .fa-check {
    background-color: #5cb85c;
    color: white;
    padding: 0.5em;
    border-radius: 0.3em;
    margin-left: 0.5em;
  }

  .economics-table .fa-x {
    background-color: #d9534f;
    color: white;
    padding: 0.5em;
    border-radius: 0.3em;
    margin-left: 0.5em;
  }

  .fa-check:hover, .fa-x:hover {
    cursor: pointer;
  }
  
  .approval-button:hover {
    opacity: 0.9;
  }

  .approval-input.partially {
    border: 0.2em solid #f0ad4e;
  }

  .comment-input {
    margin-top: 0.5em;
  }

  .disapprove-comment-input {
    width: 15em;
  }

  .orange-border {
    border: 0.1em solid #f0ad4e;
    border-radius: 0.3em;
  }

  .confirmed-receipt {
    margin-left: 1em;
  }

  .create-budget-request-table textarea {
    width: 100%;
    border: 0.05em solid #ededed;
    border-radius: 0.15em;
  }



  