* {
    box-sizing: border-box;
    cursor: default;
  }
  
  .school-parent {
    background-color: white;
    margin: auto;
  }
  
  .school-home {
    margin: auto;
    text-align: center;
    max-width: 1600px;
  }

  .twl-color {
    color: #0c2148;
    font-size: 1.15em;
  }
  
  .center {
    display: flex;
    justify-content: center;
    }
  
  .left {
    text-align: left;
  }
  
  .front-img {
    width: 100%;
  }
  
  .statement {
    margin: 1em 0.5em;
    margin-top: 3.5em;
    color: #0c2148;
  }
  
  .paragraph {
    padding: 0 2em;
  }
  
  .bodyMargin {
    margin: 4em 10em;  
    margin-top: 2em;
  }
  
  @media screen and (max-width: 1000px) {
    .statement {
      margin-top: 2em;  
    }
  }
  
  @media screen and (max-width: 800px) {
    .statement {
      margin-top: 3.5em;
      font-size: 1.4em;
    }
  }
  
  @media screen and (max-width: 396px) {
    .statement {
      margin-top: 5em;
    }
  }
  
  @media screen and (max-width: 350px) {
    .statement {
      margin-top: 4.5em;
    }
  }
  
  @media screen and (max-width: 1000px) {
    .bodyMargin {
      margin: 3em 1.5em;
    }
  }
  
  .left {
    text-align: left;
  }
  
  
  @media screen and (min-width: 800px) {
    .flex-image-text div {
      padding-right: 0.9em;
    }
  }
  
  @media screen and (max-width: 800px) {
    .flex-image-text {
      flex-flow: column wrap;
      padding: 0;
    }
  
    .flex-image-text img {
      max-height:none;
      max-width: 100%;
      margin: 0;
      margin-top: 0.5em;
    }
  
    .paragraph {
      padding: 0 0.5em;
    }
  }
  
  .img {
    width: 100%;
    height: 100%;
    border-radius: 0;
    user-select: none;
  }
  
  .grey {
    color: rgba(128, 128, 128, 0.3);
    text-decoration: line-through;
  }
  
  .row {  
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
  }
  
  @media screen and (max-width: 700px) {
    .row {   
      flex-direction: column;
    }
  }
  
  .blueBackground {
    background-color: rgba(211, 211, 211, 0.2);
    padding: 2em;
    padding-bottom: 1em;
    margin-bottom: 1.5em;
    border-left: 0.2em solid #E6F5FD;
  }
  
  @media screen and (max-width: 700px) {
    .blueBackground {
      padding: 1em;
    }
  }
  
  .visitTwl {
    border: 1px solid #f2f2f2;
    transition: transform .2s;
    cursor: pointer;
    padding: 0.2em;
  }
  
  .visitTwl:hover {
    transform: scale(1.015);
  }
  
  .visitTwl:hover, .visitTwl p:hover, .visitTwl i:hover {
    cursor: pointer;
  }
  
  .dropdown-button {
    cursor: pointer;
    background-color: #f2f2f2;
    color: #0c2148;
    padding: 10px 15px;
    margin-top: 20px;
    margin-right: 1em;
    display: inline-block;
    user-select: none;
    font-size: 1em;
    transition: background-color 0.2s, color 0.2s, transform 0.2s;
    font-weight: bold;
  }
  
  .dropdown-button:hover {
    background-color: #E6F5FD;
    color: #0c2148;
    transform: scale(1.03); 
  }
  
  
  .dropdown-button i {
    color: rgba(128, 128, 128, 0.5);
    display: none;
  }
  
  
  .dropdown-button i:hover {
    cursor: pointer;
  }
  
  .active-dropdown {
    background-color: #E6F5FD;
  }
  
  .switchBtns-container {
    display: flex;
    justify-content: start;
  }

  
  .initiative-container {
    display: flex;
    align-items: center;
    background-color: #f4f4f4;
    padding: 0 0.5em;
    margin: 1.5em;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); 
  }
  
  .initiative-logo {
    max-width: 7em; 
    margin: auto 1.4em; 
  }
  
  .initiative {
  color: #0c2148;
  padding: 1em;
  line-height: 1.6; 
  }
  
  
  @media screen and (max-width: 700px) {
    .dropdown-button {
      width: 100%;
      margin: 0.5em;
      margin-left: 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-weight: 500;
    }
  
    .dropdown-button:last-of-type {
      margin-bottom: 1em;
    }
  
    .dropdown-button i {
      display:inline;
    }
  
    .switchBtns-container {
      display: block;
    }
  
    .admitted {
      line-height: 1.8em;
    }
    
  
    .initiative-container {
      padding: 0 0.5em;
      margin: 0.5em;
      flex-direction: column;
    }
  
    .initiative-container img {
      padding-top: 1.5em;
      width: 100%;
    }
  
    .initiative-logo:last-of-type {
      display: none;
    }
  }
  
  .flex-container-2 {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    }
  
  .center {
    text-align: center;
  }