/* Banner.css */

.banner-container {
    position: relative;
    width: 100%;
    /* width: 100%;
    display: inline-block;
    user-select: none; */
  }
  
  .banner-image {
    width: 100%;
    height: auto;
    display: block;
  }

  .banner-text {
    position: absolute;
    bottom: -10%;
    margin-left: 3vw;
    width: auto;
    background-color: #E6F5FD;
    color: white;
    padding: 0.5em 4em;
    animation-delay: 1s;
    text-align: left;
  }

  .banner-text h2 {
    color: #0c2148;
    animation: slideDownFadeIn 1s ease-in-out forwards;
  }

  .banner-text p {
    font-size: 1rem; 
    margin-bottom: 1.2em; 
    color: #0c2148f4;
    animation: slideDownFadeIn 1s ease-in-out forwards;
  }
  

  /* .banner-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0); 
    z-index: 0;
  } */

  @keyframes slideDownFadeIn {
    from {
      opacity: 0;
      transform: translateY(-100%);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  /* .banner-text h1 {
    font-size: calc(1vw + 1vh + 0.5vmin)+1; 
    animation: slideDownFadeIn 1s ease-in-out forwards;
  }
  
  .banner-text p {
    font-size: 1rem; 
    margin-bottom: 1.2em; 
    color: #0c2148f4;
    animation: slideDownFadeIn 1s ease-in-out forwards;
  }
  
  .apply-now-button {
    background-color: #1ba0f2;
    color: white;
    border: none;
    padding: 10px 20px;
    text-transform: uppercase;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .apply-now-button:hover {
    background-color: #0283d3; 
  } */

  @media (max-width: 1000px) {
    .banner-text { 
      font-size: 0.7em;
    } 
  }

  @media (max-width: 800px) {
    .banner-text { 
      font-size: 0.5em;
      bottom: -25%;
      padding: 0.5em 1em;
    }

    .banner-text p { 
      font-size: 1.2em;
    }
  }

  @media (max-width: 500px) {
    .banner-text { 
      font-size: 0.5em;
      bottom: -25%;
      margin-left: 0;
      width: 100%;
    }
  }  
  
  @media (max-width: 460px) {
    .banner-text { 
      font-size: 0.5em;
      bottom: -40%;
      margin-left: 0;
    }
  }  
  
  @media (max-width: 395px) {
    .banner-text { 
      font-size: 0.5em;
      bottom: -77%;
      text-align: center;
    }
  }