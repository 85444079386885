.school-navbar {
    width: 100%;
    position: relative;
    user-select: none;
    background-color: #fff;
    box-shadow: 0 4px 8px rgba(0,0,0,.16);
    z-index: 100;
  }
  
  .school-navbar-main {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 0;
  }
  
  .school-navbar-main img {
    height: 2.3em;
    margin-top: 0.2em;
    margin-right: 0.6em;
  }
  
  .school-navbar-main img:hover {
    cursor: pointer;
  }
  
  .school-navbar-hover {
    display: none;
    position: absolute;
    width: 100%;
    top: 100%;
    left: 0;
    background-color: rgba(255, 255, 255, 0.9);
    z-index: 10;
    padding: 0;
  }
  
  .school-navbar-items {
    display: flex; 
  }
  
  .school-navbar-items a,
  .school-navbar-hover a {
    text-decoration: none;
    color: #000;
    transition: color 0.3s ease;
    display: inline-block; 
    height: 100%;
    padding: 0.7em;
    margin: 0.6em;
    font-weight: bold;
    transition: background-color 0.2s, color 0.2s, transform 0.2s;
  }
  
  .school-navbar-items a:hover,
  .school-navbar-hover a:hover {
    color: #0c2148;
    transform: scale(1.05); 
    cursor: pointer;
    background-color: #E6F5FD;
  }
  
  .school-navbar-hover a:hover {
    display: block;
  }
  
  .school-apply-button {
    font-size: 0.95em;
    background-color: #1ba0f2;
    border: none;
    color: white;
    padding: 0.6em 1.2em;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .school-apply-button:hover {
    background-color: #0283d3;
  }
  
  .school-navbar-logo span {
    font-size: 0.9em;
  }
  
  .school-navbar-logo {
    color: #443c3e;
    font-size: 0.95em;
    margin-bottom: 0.2em;
  }
  
  
  .school-navbar-logo:hover {
    cursor: pointer;
  }
  
  .school-navbar-toggle {
    display: none;
    font-size: 1.5em; 
    padding-right: 0.5em;
    cursor: pointer;
  }

  .school-navbar .fa-bars {
    color: #443c3e;
  }
  
  .school-donateLink {
    display: none;
  }
  
  .school-navbar-main.school-donate {
    padding: 0;
    background-color: inherit;
  }
  
  @media (max-width: 1100px) {
    .school-navbar-main {
      margin: auto 0.5em;
    }
  
    .school-navbar-logo {
      margin-bottom: 1em;
      margin: 0.5em;
    }
  
    .school-navbar-items a,
    .school-navbar-hover a {
      padding: 0.8em 0.7em;
    }
  }
  
  @media (max-width: 950px) {
    .school-navbar-logo {
      display: none;
    }
  
    .school-navbar-mobile {
      margin: 1em auto;
    }
  }
  
  @media (max-width: 745px) {
    .school-navbar-main {
      flex-direction: column;
      margin: 0;
    }

    .school-navbar-main.school-donate {
      display: none;
    }
  
    .school-navbar-items {
      display:none;
    }
  
    .school-navbar-items.show {
      display: flex;
      flex-direction: column;
      width: 100%;
    }
  
    .school-navbar-mobile {
      display: flex;
      justify-content: space-between;
      width: 100%; 
    }
    
    .school-navbar-mobile div {
      margin: auto 0.5em;
    }
    
    .school-navbar-toggle {
      display: block; 
    }
  
    .school-donateLink {
      display: block;
      background-color: #E6F5FD;
      color: white;
      box-shadow: 0 4px 8px rgba(0,0,0,.16);
    }
  
    .school-navbar-items a {
      display: flex;
      flex-direction: column;
      margin: 0;
      padding: 0.5em;
      border-top: 0.1em solid rgba(128, 128, 128, 0.4);
    }
  
    .school-navbar-logo {
      display: block;
    }
  }
  