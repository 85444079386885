.attendance-page {
    background-color: #f9f9f9;
    padding: 20px;
    min-height: 400px;
}

.student-attendance-table-parent {
    overflow-x: auto;
    padding: 0.5em;
  }


.student-attendance-table table {
  width: 100%;
  table-layout: fixed; 
  border-collapse: collapse;
  user-select: none;
}

.student-attendance-table th,
.student-attendance-table td {
    border: 1px solid #ccc;
    padding: 10px;
    text-align: left;
}

.student-attendance-table tr:first-child {
  text-align: center;
}

.student-attendance-table tr:nth-child(even) {
    background-color: #f2f2f2;
}

.student-attendance-table tr:hover {
    background-color: #e6f7ff;
}

  
  .student-attendance-table td:first-child {
    text-align: left;
  }


  .todays-lessons {
    text-align: left;
    font-size: 0.8em;
  }


  .lightbluebg {
    background-color: #E6F5FD;
  }

  .student-attendance-time-input {
    min-width: 100px;
  }
  
  .attendance-item {
    font-size: 0.8em;
  }

  .confirmed-info {
    font-size: 0.8em;
    font-weight: bold;
  }

  .reopen-attendance {
    font-size: 0.7em;
  }

  .create-lesson-parent {
    display: flex;
    align-items: center;
    gap: 1em;
    margin-bottom: 1em;
    background-color: #f2f2f2;
    padding: 0.5em;
    left: 0;
    border: 1px solid #ccc;
}

  .small-font {
    font-size: 0.8em;
  }

  .calendar-container {
    display: inline-flex;
    align-items: center;
    gap: 10px; /* Adjust space between the icon and text */
  }
  
  .week-navigation {
    display: flex;
    align-items: center;
    justify-content: left; /* Keeps Previous/Next buttons aligned */
    gap: 10px; /* Adds spacing between elements */
  }
  


.lesson-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.lesson-abbreviation {
  cursor: pointer;
}

.unconfirm-text {
  font-weight: 100;
}

.confirm-buttons-row td {
  text-align: center;
}

.confirm-buttons-row button {
  padding: 5px 10px;
  margin: 5px;
  font-size: 0.9rem;
  cursor: pointer;
}

.confirm-button {
  background-color: #28a745; /* Bootstrap's Success Green */
  color: white;
  border: none;
  padding: 0.5em 1em;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1em;
  transition: background-color 0.3s ease;
}

.confirm-button:hover {
  background-color: #218838; /* Slightly darker green on hover */
}

.confirm-button:disabled {
  background-color: #c3e6cb; /* Light green for disabled state */
  cursor: not-allowed;
  opacity: 0.6;
}

.confirm-buttons-row button:hover {
  background-color: #f0f0f0;
}

/* Parent container for dropdown */
.dropdown-container {
  position: relative;
  display: inline-block;
}

/* Dropdown select positioning */
.attendance-dropdown {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10; /* Ensures dropdown is above other elements */
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  min-width: 100px; /* Ensures consistent width */
}

/* Ensure dropdown text fits well */
.attendance-dropdown option {
  white-space: nowrap;
}

/* Interaction effects */
.attendance-dropdown:focus {
  outline: none;
  border-color: #007bff;
}

/* Overlay for popup */
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

/* Popup content styling */
.popup-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 600px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  text-align: left;
}

/* Style for the popup buttons */
.popup-buttons {
  display: flex;
  gap: 0.2em;
  margin-top: 0.5em;
}


.popup-create-button i {
  color: white
}

.popup-create-button:hover {
  background-color: #45a049;
}

.popup-create-button {
  background-color: #4caf50;
  color: white;
  border: none;
  padding: 0.4em 0.8em;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1em;
  font-weight: bold;
}

.popup-cancel-button {
  background-color: #f44336;
  color: white;
  border: none;
  padding: 0.4em 0.8em;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
}

.popup-cancel-button:hover {
  background-color: #e53935;
}

/* Textarea styling for lesson report */
.lesson-report-textarea {
  width: 350px;
  border-radius: 4px;
  border: 1px solid #ccc;
  padding: 10px;
  font-size: 14px;
  resize: none;
}

.lesson-info-icon {
  margin-left: 8px;
  color: grey;
  cursor: pointer;
}

.lesson-report-tooltip {
  display: none;
  position: absolute;
  background-color: #ffffff;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 8px;
  font-size: 12px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  max-width: 400px;
  word-wrap: break-word;
}

.fa-pen-to-square, .fa-envelope-open-text {
  color: grey;
}

.lesson-container {
  position: relative;
}

.attendance-details {
  font-weight: normal;
}

.confirmed-attendance-td {
  background-color: #4caf4f10;
  cursor: not-allowed;
}

.confirmed-attendance-todays {
  background-color: #4caf4f20;
  cursor: not-allowed;
}

.editable-text {
  color: #333; /* Default color for editable text */
  cursor: pointer;
}

.icon-confirmed-absent {
  color: darkred;
  opacity: 0.7;
}