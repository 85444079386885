.article.donateoptions, .article.sponsoroptions {
    display: flex;
    justify-content: space-between;
    background-color: inherit;
    box-shadow: none;
}

.article.donateoptions .option, .article.sponsoroptions .option {
    flex: 1;
    text-align: center;
    padding: 0.5em 0.2em;
    margin: 0 0.4em;
    border-radius: 0.3em;
    user-select: none;

    border-radius: 0.4em;
    display: inline-block;
    transition: transform .2s;
    user-select: none;
    cursor: pointer;

    background-color: white;
    border: 0.01em solid lightgrey;
    border-bottom: 0.2em solid rgba(128, 128, 128, 0.5);
    border-bottom-style: groove;
    user-select: none;
    color: #443c3e;
  }

  .article.sponsoroptions {
    margin-top: 0em;
    margin-bottom: 0em;
    padding: 0 1em;
  }

  .article.donateoptions .option:first-of-type, .article.sponsoroptions .option:first-of-type {
    margin-left: 0;
  }  


  .article.donateoptions .option:last-of-type, .article.sponsoroptions .option:last-of-type {
    margin-right: 0;
  }

  .article.donateoptions .option:hover p,  .article.donateoptions .option i:hover,
  .article.sponsoroptions .option:hover p, .article.sponsoroptions .option i:hover  {
    cursor: pointer;
  }

  .article.donateoptions .option:hover p, .article.donateoptions .option i:hover, 
  .article.sponsoroptions .option:hover p, .article.sponsoroptions .option i:hover {
    transition: transform 0.2s ease;
    transform: scale(1.05);
  }

  .article.donateoptions .option .active {
    background-color: #ccc;
  }

  @media (max-width: 800px) {
    .article.donateoptions {
     margin: 0;
     padding: 0.6em;
    }

    .article.sponsoroptions {
      margin: 0;
      padding: 0;
    }
  }  

  .student-box {
    /* border: 1px solid grey; */
    max-width: 20em;
    padding: 0.5em
  }

  .student-image {
    max-width: 100%;
  }

  /* Style for the hideable content */
  .hideable-content {
    /* border: 1px solid #ccc; */
    padding: 0.5em 1em;
    margin-bottom: 1em;

    border-radius: 0.5em;
    font-size: 0.9em;
    border: 0.4em solid #E6F5FD;
  }

.hideable-content p {
  margin-bottom: 10px;
  line-height: 1.5em;
  margin-left: 0.4em;
  margin-bottom: 0.45em;
  margin-top: 0.05;
  font-size: 0.95em;
}

.hideable-content h3 {
  margin-bottom: 0.8em;
}

.hideable-content h3:not(:first-of-type) {
  margin-top: 2em;
}

.show-hide-button {
  margin-bottom: 10px;
}

.sponsorshipInfo {
  padding: 0.5em 1em;
  border-radius: 0.3em;
  margin-top: 2em;
  margin-bottom: 1em;
  transition: transform .2s;
}

.sponsorshipInfo.admin {
  margin-top: 0em;
}

.sponsorshipInfo:hover, .sponsorshipInfo i:hover {
  cursor: pointer;
  transform: scale(1.02);
}

.show-hide-button button:hover {
  background-color: #218838;
}

.green-border {
  border: 0.4em solid rgba(144, 238, 144, 0.3);
  border-radius: 0.5em;
  padding: 1em;
}


/* Style for the close button */
.closeInfo {
  margin-top: 1em;
  margin-bottom: 0.5em;
}

.closeInfo {
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 3px;
}


.goToStudent {
  font-size: 0.9em;
  transition: transform .2s;
  padding: 0.6em 0.5em;
}

.goToStudent:hover {
  transform: scale(1.02);
}

@media (max-width: 800px) {
  .sponsorshipInfo:not(:first-of-type) {
    margin-top: 0;
 }

 .sponsorshipInfo {
  min-width: 100%;
  text-align: center;
 }

}  


.students-preview-container {
  text-align: center;
  margin: 20px 0;
}

.students-preview-container *:hover {
  cursor: pointer;
}

.students-preview {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 20px;
  justify-content: center;
}

@media (max-width: 500px) {
  .students-preview {
    grid-template-columns: 1fr; /* Show one student per row */
    gap: 10px; /* Reduce the gap for a more compact layout */
  }
}

.student-card {
  border-radius: 8px;
  padding: 10px;
  text-align: center;
  transition: transform 0.3s ease;
  box-shadow: 0 0.2em 0.25em 0 rgba(0, 0, 0, 0.2);
  cursor: pointer;
  border: 0.2em solid #1ba0f220;
}

.student-card:hover {
  transform: scale(1.05);
}

.student-card-image {
  width: 100%;
  border-radius: 0.3em;
  height: auto;
  /* max-width: 100px; */
}

.student-card-name {
  font-weight: bold;
}


.student-message-container {
  text-align: center;
  margin: 1em 0;
  margin-bottom: 3em;
}

.student-message {
  font-size: 0.9em;
}

.message-navigation {
  display: flex;
  flex-wrap: wrap; /* Allow wrapping when needed */
  align-items: center; /* Align items vertically */
  justify-content: space-between; /* Space out items when there's room */ /* Add spacing between items */
  margin-top: 1em;
  gap: 0.3em;
}

.message-navigation h3 {
  flex: 1; /* Allow the header to take up flexible space */
  margin: 0;
  white-space: nowrap; /* Prevent wrapping of the header text */
}

.message-navigation button {
  padding: 0.3em 0.6em;
  border: none;
  background-color: #E6F5FD;
  border-bottom: 0.15em solid #1ba0f2;
  cursor: pointer;
  color: #333;
  cursor: pointer;
  border-radius: 0.3em;
  font-size: 0.9em;
  transition: background-color 0.3s ease;
  flex: 0 0 auto; /* Prevent buttons from shrinking */
  max-width: 150px; /* Optional: Set a reasonable max width for buttons */
  white-space: nowrap; /* Prevent wrapping of button text */
}

/* On small screens, ensure proper wrapping */
@media (max-width: 600px) {
  .message-navigation {
    justify-content: center; /* Center the items on smaller screens */
  }

  .message-navigation h3 {
    flex: 1 1 100%; /* Move the header to its own line */
    text-align: center;
    margin-bottom: 0.5em;
  }

  .message-navigation button {
    flex: 1 1 45%; /* Buttons share space and wrap properly */
    font-size: 0.8em;
    max-width: 100px;
  }
}

.payment-alternative-info {
  font-size: 0.85em;
  margin: 0;
  text-align: left;
}

.payment-alternative-info i {
  color:#1ba0f2;
}

.login-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}
.login-modal {
  background: white;
  padding: 20px;
  border-radius: 10px;
  width: 400px;
  text-align: center;
}
.close-modal {
  position: absolute;
  top: 10px;
  right: 10px;
  border: none;
  background: none;
  font-size: 20px;
  cursor: pointer;
}