

  .report-list {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    min-height: 500px;
  }
  
.report-table {
  display: flex;
  align-items: center; /* Vertically centers the content */
  justify-content: space-between; /* Adjusts spacing between items */
  background-color: #fff;
  padding: 0 20px;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease-in-out;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex-wrap: wrap; /* Allows content to wrap onto the next line if too wide */
  margin: 0.5em 0;
}

.report-info {
  font-size: 0.8em;
  border-radius: 0.3em;
  padding: 1em;
  padding-top: 0;
}

.report-info p {
  line-height: 1;
}

.report-table td {
  width: 250px; 
}

.report-table td:nth-child(2) {
  text-align: left;
}

.report-table h3 {
  color: #004a99;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex: 1 1 auto; /* Allow h3 to take up as much space as needed */
  margin: 0; /* Reset margin to control spacing */
}

.report-table p {
  margin: 5px 0;
  flex: 1 1 auto; /* Allows the paragraph to adjust with flex wrapping */
}

.report-table button {
  width: auto; /* Keep the button width auto by default */
  flex: 0 1 auto; /* Allow the button to grow or shrink as needed */
}

.report-table > :last-child {
  flex: 0 1 auto; /* Allow the last child to take only necessary space */
  text-align: right; /* Optional: align button or content to the right */
}

@media (max-width: 670px) {
  .report-table > :last-child {
    flex: 1 1 100%; /* On smaller screens, make it full width */
    margin-top: 10px; /* Add margin for separation if content wraps */
    text-align: center; /* Optional: center-align the content for smaller screens */
  }
}


  
  /* Button Styles */
.feedback-read-btn {
    background-color: #007bff;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.2s ease-in-out;
    margin-top: 10px;
  }
  
  .feedback-read-btn:hover {
    background-color: #0056b3;
  }
  
  /* Feedback read confirmation text */
  .feedback-read-confirmation {
    color: green;
    font-weight: bold;
    margin-top: 10px;
  }
  
  /* Responsiveness */
  @media screen and (max-width: 768px) {
    .report-list {
      padding: 10px;
    }
  
    .report-table {
      padding: 15px;
    }
  
    .feedback-read-btn {
      width: 100%;
    }
  }

  /* Styles for Report Detail Page */
.report-form {
    margin-top: 20px;
    min-height: 500px;
  }
  
  .report-textarea {
    width: 100%;
    height: 200px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    resize: none;
    font-size: 16px;
  }
  

  .submit-report-btn:disabled {
    cursor: not-allowed;
  }
  
  .create-report-btn, .edit-report-btn, .submit-report-btn, .go-back-btn, .delete-draft-btn, .view-report-btn, .orange-btn  {
    background-color: #28a745;
    color: white;
    padding: 10px 20px;
    font-size: 1em;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }

  .create-report-btn, .submit-report-btn {
    background-color: #28a745;
  }

  .report-submitted-p {
    color: #28a745;
    font-weight: bold;
  }

  .overdue-warning {
    color: #d9534f;
    font-weight: bold;
  }

  .create-report-btn:hover, .submit-report-btn:hover {
    background-color: #218838;
  }

  .view-report-btn, .edit-report-btn {
    background-color: #007bff;
  }
  
  .view-report-btn, .edit-report-btn:hover {
    background-color: #31b0d5;
  }

  .go-back-btn {
    background-color: #007bff;
  }  
  
  .delete-draft-btn {
    background-color: #d9534f;
  }

  .delete-draft-btn:hover {
    background-color: #c9302c;
  }

  .orange-btn {
    background-color: #f0ad4e;
  }

  

  .button-group {
    display: flex;
    gap: 10px; /* Space between buttons */
  }
  

  
  .save-draft-btn:hover {
    background-color: #ec971f;
  }
  
  .draft-saved-message {
    color: green;
    font-weight: bold;
    margin-top: 10px;
  }

  .report-content {
    background-color: #f9f9f9; /* Light background color for readability */
    padding: 20px; /* Add some padding for spacing */
    border-radius: 8px; /* Round the corners */
    border: 1px solid #ddd; /* Subtle border */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Add a slight shadow for depth */
    font-family: 'Arial', sans-serif; /* Clean font for readability */
    color: #333; /* Darker text color for better contrast */
    max-width: 100%; /* Ensure the content doesn’t overflow */
    word-wrap: break-word; /* Handle long words or URLs */
    font-size: 0.9em;
    margin-top: 0.3em;
  }

  .report-container {
    margin: auto;
    max-width: 1600px;
  }

  .report-page {
    min-height: 500px;
  }

  .sticky-container {
    position: fixed;
    bottom: 0; /* Distance from the top of the viewport */
    left: 0; /* Align to the left of the parent */
    width: 100%; /* Match the width of the parent */
    background: white; /* Prevent overlap with content */
    z-index: 10; /* Ensure it stays above other elements */
    padding: 1em; /* Add some spacing */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Optional shadow */
  }
  


  