.timetable {
    width: 100%;
    border: 0.8em solid white;
    border-radius: 0.3em;
  }
  
.subjectstable {
    width: 100%
}

.subjectstable td {
    vertical-align: top;
    width: 50%;
}

@media(max-width: 1000px){ 
    .timetable {
        width: 100%;
      }
}

@media screen and (max-width: 768px) {
    .subjectstable, .subjectstable thead, .subjectstable tbody, .subjectstable th, .subjectstable td, .subjectstable tr {
      display: block;
    }
  
    .subjectstable td {
      /* Adjustments for cell spacing and alignment */
      margin-bottom: 0.4em;
      width: 100%    
    }
  
    .subjectstable td:before {
      /* Content for labeling the data */
      content: attr(data-label);
      float: left;
      font-weight: bold;
      text-transform: uppercase;
    }
  
    /* Additional styling as needed */
  }
  