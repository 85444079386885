.sponsorship-includes {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    gap: 16px;
    margin-top: 1em;
    margin-bottom: 1em;
  }
  
  .sponsorship-includes__item {
    flex: 1 1 calc(25% - 16px); /* 4 items per row by default */
    text-align: center;
    box-sizing: border-box;
  }
  
  .sponsorship-includes__item img {
    width: 100%;
    height: auto;
    max-width: 150px;
    padding: 0.5em;
  }
  
  .sponsorship-includes__item p {
    margin-top: 8px;
    font-size: 14px;
  }
  
  /* Responsive Layout */
  @media (max-width: 600px) {
    .sponsorship-includes__item {
      flex: 1 1 calc(50% - 16px); /* 2 items per row on small screens */
    }

    .sponsorship-includes {
        margin-bottom: 3em;
    }
  }
  