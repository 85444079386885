/* LoginPopup.css */

/* Popup Background and Container */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  
  .modal-container {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    max-width: 500px;
    width: 90%;
    position: relative;
  }
  
  /* Close Button */
  .close-button {
    background: none;
    border: none;
    font-size: 1.2em;
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
  }
  
  /* Main Login Popup Styles */
  .login-popup {
    padding: 20px;
    align-items: center;
  }
  
  /* Header */
  .login-popup-header {
    width: 100%;
    margin-bottom: 1.5rem;
  }
  
  /* Option Selector (Sponsor vs Staff & Admin) */
  .login-popup-options {
    width: 100%;
    display: flex;
    justify-content: space-around;
    margin-bottom: 1rem;
  }
  
  .option {
    width: 50%;
    cursor: pointer;
    padding: 0.2em;
    margin: 0 0.2em;
    text-align: center;
    border: 0.1em solid #1ba0f220;
    border-bottom: 0.15em solid #1ba0f2;
    border-bottom-style: groove;
    background-color: #f9f9f9;
    border-radius: 0.3em;
    transition: transform 0.2s ease;
  }
  
  .option:hover {
    transform: scale(1.05);
  }
  
  .option.active {
    font-weight: bold;
    border-bottom: 0.25em solid #1ba0f2;
  }

  .login-logo {
    max-width: 7em;
    margin-bottom: 0;
    margin-left: -0.8em;
    margin-top: -1em;
  }

  .login-popup-header h2 {
    margin: 0;
  }

  .full-width {
    width: 100%;
  }
  
  /* Form Styles */
  .login-popup form {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  
  .login-popup label {
    margin-bottom: 0.5rem;
    font-weight: bold;
  }
  
  .login-popup input {
    padding: 0.5rem;
    margin-top: 0.5em;
    margin-bottom: 1rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 100%;
  }
  
  /* Button Styling */
  .login-popup-button {
    background-color: #007bff;
    color: white;
    padding: 0.6rem;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.2s;
    margin-top: 10px;
  }
  
  .login-popup-button:hover {
    background-color: #0056b3;
  }
  
  /* Loading Spinner and Reset Link */
  .loading-spinner {
    margin-left: 10px;
  }
  
  .reset-toggle {
    cursor: pointer;
    color: blue;
    text-decoration: underline;
    margin-top: 1rem;
  }
  
  /* Error Message */
  .error {
    margin-top: 1em;
    color: #ff0000;
    text-align: center;
    font-size: 0.9em;
  }
  
  /* Responsive Styles */
  @media (max-width: 700px) {
    .modal-container {
      padding: 10px;
      width: 95%;
    }
    .login-popup {
      padding: 0.5em;
    }
    .option {
      margin-bottom: 0.5em;
    }
  }
  