.budget-container {
    max-width: 1000px;
    margin: 20px auto;
    padding: 20px;
    background: white;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }
  
  /* ===========================
     Receipt Uploader
     =========================== */
  .receipt-uploader {
    /* display: flex;
    flex-direction: column; */
    gap: 10px;
    background: #fff;
    padding: 15px;
    border-radius: 6px;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
  }
  
  .receipt-input,
  .receipt-file-input {
    padding: 8px;
    border-radius: 4px;
    font-size: 14px;
    /* width: 100%; */
  }
  
  
  /* ===========================
     Budget Card
     =========================== */
  .budget-card {
    position: relative;
    border: 1px solid #ddd;
    border-radius: 6px;
    padding: 15px;
    background: white;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
    text-align: center;
    margin-bottom: 1em;
  }

  .budget-card p {
    font-size: 14px;
    margin: 5px 0;
  }
  
  .budget-card strong {
    color: #333;
  }
  
  /* ===========================
     Receipts Section
     =========================== */
  .receipt-item {
    padding: 0;
  }
  
  .receipt-link {
    color: #007bff;
    text-decoration: none;
  }
  
  .receipt-link:hover {
    text-decoration: underline;
  }
  
  /* ===========================
     Budget Form
     =========================== */
  .budget-form {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
  
  .budget-form select,
  .budget-form input {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
  }
  
  .budget-form button {
    background: #28a745;
    color: white;
    padding: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
    transition: background 0.3s ease;
  }
  
  .budget-form button:hover {
    background: #218838;
  }
  
  .budget-form button:disabled {
    background: gray;
    cursor: not-allowed;
  }
  
  /* ===========================
     Budget Header
     =========================== */
  .budget-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  
  .budget-header h2 {
    margin: 0;
    font-size: 20px;
    color: #333;
  }
  
  .back-button {
    background: #dc3545;
    color: white;
    border: none;
    padding: 8px 12px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
  }
  
  .back-button:hover {
    background: #c82333;
  }

  .progress-container {
    width: 100%;
    background: #e0e0e0;
    border-radius: 10px;
    height: 12px;
    margin-top: 10px;
    position: relative;
    overflow: hidden;
  }
  
  .progress-bar {
    height: 100%;
    border-radius: 10px;
    transition: width 0.5s ease-in-out, background-color 0.5s ease-in-out;
  }
  
  .progress-text {
    font-size: 14px;
    text-align: center;
    color: #555;
    margin-top: 5px;
    font-weight: bold;
  }

  .light {
    font-weight: 100;
  }

  .delete-receipt, .delete-budget {
    color: red;
    margin: 0 1em;
    font-size: 1.2em;
  }

  .delete-budget {
    position: absolute;
    top: 0.3em;
    right: 0;
    font-size: 18px;
    cursor: pointer;
    background: transparent;
    border: none;
}

.budget-form-table{
  width: 100%;
  border-collapse: collapse;
}

.budget-form-table td {
  padding: 0.3em;
  vertical-align: middle;
}

.budget-form-table label {
  font-weight: bold;
}
