.concept-table {
    width: 100%; /* Full width for mobile */
    border-collapse: collapse;
    margin-bottom: 1em;
    margin-top: 2em;
    word-wrap: break-word;
}

.concept-table td {
    padding: 1em;
    width: 15%;
}

.concept-table td:last-child {
    width: 55%;
}

.concept-table tr:not(:first-child) td:nth-child(3) {
    color: green;
}

.concept-table tr:not(:first-child) td:nth-child(2) {
    color: red;
}


@media screen and (max-width: 1000px) {
    .concept-table tr {
        display: flex; /* Make each row a flex container */
        flex-wrap: wrap; /* Allow wrapping for cells */
        margin-bottom: 3em; /* Space between rows */
        text-align: center;
    }    
    

    .concept-table tr:first-of-type {
        border-bottom: 0.1em solid lightblue;
        padding-bottom: 1em;
        margin-bottom: 1em; 
    }

    .concept-table td {
        flex: 1 1 30%; 
        box-sizing: border-box;
        border-bottom: none;
        padding-bottom: 0;
    }

    .concept-table td:first-of-type {
        font-weight: bold;
        margin-bottom: 0.5em;
        text-align: left;
    }    
    
    .concept-table td:last-of-type{
        text-align: left;
    }

    .whyTd {
        display: none;
    }
}

@media screen and (max-width: 500px) {
    .concept-table td {
        padding: 0.2em;
    }

}
