/* Features.css */

.features-section {
    display: flex;
    justify-content: space-around;
    align-items: stretch;
    padding: 1.3em;
    margin: 3em;
    margin-top: 0;
    border-bottom: 0.05em solid grey;
  }

.features-section.how {
  border-bottom: 0;
  margin-bottom: 0;
}

.features-section.how .feature {
  background-color: #E6F5FD;
  margin: 1em;
  margin-top: 0;
  margin-bottom: 2em;
  padding: 1em;
  transition: transform .2s;
}


  
  .feature {
    text-align: center;
    max-width: 20em; 
    margin: 0 1em;
    width: 25%;
  }

  .feature img {
    max-width: 3em;
  }

  @media screen and (max-width: 1100px) {
    .features-section {
      padding: 0;
    }

  }

  @media screen and (max-width: 800px) {
    .features-section {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 1.3em 0; 
      margin: 0 auto; 
      border-top: 0.05em solid grey;
    }
  
    .feature {
      text-align: center;
      min-width: 100%;
      margin: 1em 2em; 
      padding: 0 2em;
    }
  
    .feature img {
      width: 100%; /* This will make the image responsive and fit to the container width */
      height: auto; /* This will maintain the image aspect ratio */
      max-width: 3em; /* This ensures that the image does not exceed a width of 3em */
    }

    .features-section.how {
      margin: 2em
    }

    .features-section.how .feature {
      margin: 1em;
      margin-top: 0;
      margin-bottom: 2em;
      padding: 1em;
      transition: transform .2s;
    }    
    
    .features-section.how .feature:last-of-type {
      margin-bottom: 0;
    }
  }
  
  
  .feature-icon {
    font-size: 1.5rem; 
    margin-bottom: 0;
  }
  
  .feature-title {
    font-size: 1.1rem;
    margin-top: 0.5em;
    margin-bottom: 0em;
  }
  
  .feature-description {
    font-size: 0.8rem; 
  }
  