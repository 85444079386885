.student-grades-table-parent {
    overflow-x: auto;
    border: 1px solid grey;
    padding: 0.5em;
}

.student-grades-table {
    text-align: left;
    border-collapse: collapse;
    user-select: none;
    font-size: 0.9em;
}


.student-grades-table td, .student-grades-table input {
    width: 60px;
    text-align: center;
    margin: auto;
}

.student-grades-table {
    width: 100%;
    table-layout: fixed;
    border-collapse: collapse;
}

.student-grades-table th,
.student-grades-table td {
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    text-align: center;
}

.student-grades-table tbody tr:nth-child(even) {
    background-color: #f2f2f2;
}

.student-grades-table tr:hover {
    background-color: #e6f7ff;
}


.student-grades-table td:first-child {
    min-width: 200px; 
    text-align: left;
}

.grade-input-saving {
    padding: 0.95em;
    margin: auto;
    width: 100%;
}
  
.student-grades-table th {
    max-width: 100px;
    overflow: hidden;
    text-overflow: ellipsis; /* Hides the overflowing text and shows '...' */
    white-space: nowrap;     /* Prevents text from wrapping to the next line */
    text-align: center;
    padding: 0 0.5em;   
    background-color: #E6F5FD;
}


.confirm-button {
    padding: 5px 10px;
    font-size: 0.75rem;
    color: white;
    background-color: #28a745; /* Green background */
    border: 1px solid #28a745; /* Matching border color */
    border-radius: 3px;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
}

.confirm-button:disabled {
    background-color: #c3e6cb; /* Lighter green for disabled */
    border-color: #c3e6cb;
    color: #666;
    cursor: not-allowed;
}

.student-grades-input:disabled {
    cursor: not-allowed;
}

.confirm-button:hover:not(:disabled) {
    background-color: #218838; /* Darker green on hover */
    border-color: #1e7e34;
    color: white;
}



