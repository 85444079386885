.partners-section {
    position: relative;
    padding: 1rem;
    text-align: center;
  }
  
  .edit-partners-btn {
    position: absolute;
    top: 1rem;
    right: 1.5rem;
    border-bottom: 0.15em solid grey;
    color: #333;
    padding: 0.2rem;
    cursor: pointer;
  }

  
  /* 
     SCENARIO A: fitsOnScreen = true => "no-scroll"
     We simply lay them out in a row with "justify-content: space-evenly"
     so there's bigger spacing if there's room.
  */
  .partners-list.no-scroll {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    gap: 1rem;
    margin-top: 0rem;
  }
  
  /* Each partner 200px wide, plus some margin to ensure spacing */
  .partner-item {
    width: 200px;
    text-align: center;
    margin-bottom: 0;
  }
  .partner-item img {
    width: 200px;
    height: auto;
  }
  
  /* 
     SCENARIO B: Overflows => marquee approach
     We have a container + duplication. 
  */
  
  /* The container that will hold 2 sets of items side by side for marquee */
  .marquee-container {
    position: relative;
    width: 100%;
    overflow: hidden;
    margin-top: 2rem;
  }
  
  /* We'll animate from 0 to -var(--scroll-dist) in 'marquee-content' */
  .marquee-content {
    display: flex;
    flex-wrap: nowrap;
    animation: marqueeScroll 20s linear infinite;
    transform: translateX(0);
  }
  
  /* We'll read the distance from a CSS variable. 
     If we have no distance to scroll, animation won't matter. 
  */
  @keyframes marqueeScroll {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(calc(-1 * var(--scroll-dist)));
    }
  }
  
  /* Popup overlay, forms, etc. (unchanged) */
  .popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0,0,0,0.5);
    z-index: 999;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .popup-content {
    background: #fff;
    width: 90%;
    max-width: 600px;
    border-radius: 6px;
    padding: 1.5rem;
    position: relative;
    text-align: left;
  }
  
  .popup-content h3 {
    margin-top: 0;
  }
  .edit-desc {
    margin-bottom: 1rem;
  }
  .partners-editor-list {
    margin-bottom: 1.5rem;
  }
  .partner-edit-row {
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;
    padding: 0.5rem;
    background: #f1f1f1;
    border-radius: 4px;
  }
  .partner-edit-text {
    flex: 1;
    font-weight: bold;
  }
  .partner-edit-actions {
    display: flex;
    gap: 0.5rem;
  }
  .edit-partner-form,
  .add-partner-form {
    margin: 1rem 0;
    padding: 1rem;
    background: #fdfdfd;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  .edit-partner-form h4,
  .add-partner-form h4 {
    margin-top: 0;
  }



  .close-popup-btn {
    background: #444;
    color: #fff;
    border: none;
    border-radius: 4px;
    padding: 0.6rem 1rem;
    cursor: pointer;
    margin-top: 1rem;
  }
  .close-popup-btn:hover {
    background: #555;
  }
  .popup-buttons {
    margin-top: 1rem;
    display: flex;
    gap: 0.5rem;
  }
  