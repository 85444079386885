/* InfoBar.css */

.info-bar {
    background-color: #0c2148; /* Dark blue background, adjust the color to match your design */
    color: #fff; /* White text color */
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 0.6em 0; /* Adjust padding as needed */
    font-size: 0.9rem; /* Adjust font-size as needed */
    user-select: none;
  }


  .info-item {
    display: flex;
    align-items: center;
  }

  .info-item:nth-child(2) {
   margin: auto 12em;
  }

  .pointer:hover {
    cursor: pointer;
  }
  
  .info-item i {
    margin-right: 0.4em; /* Spacing between icon and text */
    color: white;
  }

  .info-item a {
    color: white;
  }

  @media (max-width: 1100px) {
    .info-item:nth-child(2) {
      margin: auto 3em;
     }
  }   
  

  @media (max-width: 768px) {
    .info-bar {
      display: none;
    }
  }  
  
  
 
  