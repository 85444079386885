/* SwitchView.css updates for vertical centering */
.switch-view-container {
    display: flex;
    align-items: center; /* This ensures vertical centering */
    gap: 10px; /* Adds some space between the switch and the label text */
    margin-left: 2em;
  }
  
  .switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
  }
  
  
  .switch input { 
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: lightblue;
    transition: .4s;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: .4s;
  }
  
  input:checked + .slider {
    background-color: lightgreen;
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
  }
  
  input:checked + .slider:before {
    transform: translateX(26px);
  }
  
  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }
  
  .switch-label {
    font-size: 1em; /* Adjust the font size as needed */
    color: white;
  }

  @media (max-width: 800px) {
    .switch-view-container {
      margin-left: 0; /* Remove left margin */
      justify-content: center; /* Center the content horizontally */
      padding: 0.2em;
    }

    .switch-label {
      color: #333;
      font-weight: bold;
    }

    .switch-view-container {
      border-radius: 0.5em;
    }
  }
  