/* Container */
.support-form-container {
    position: relative;
    width: 100%;
    margin: auto;
    text-align: center;
    font-family: Arial, sans-serif;
    margin-top: 1em;
  }

.summary-text {
  margin-bottom: 0;
}

  .reset-button {
    color: #333;
    border: none;
    padding: 0.4em 1em;
    border-radius: 5px;
    font-size: 1em;
    cursor: pointer;
    transition: background-color 0.3s ease;
    min-width: 100%;
  }

  @media (min-width: 800px) {
    .option-item.full-width-bg {
      flex: 1 1 calc(50% - 15px); /* 50% width with gap adjustment */
    }
  }
  
  
  /* Title */
  .form-title {
    margin-bottom: 20px;
  }
  
  /* Progress Bar */
  .progress-bar-container {
    margin-bottom: 20px;
    max-width: 500px;
    margin: auto;
  }

  .progress-bar-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 15px;
  }
  
  .progress-bar-bg {
    width: 100%;
    background-color: #e0e0e0;
    height: 10px;
  }
  
  .progress-bar-fill {
    height: 10px;
    background-color: #4caf50;
  }

  
  .progress-bar-text {
    font-size: 0.9em;
    margin-top: 5px;
  }

  .progress-bar-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 15px;
  }

  /* Step Content */
  .step-content {
    margin-bottom: 0.5em;
    border-radius: 0.3em;
  }
  
  .step-title {
    font-size: 1.3rem;
    margin-bottom: 20px;
  }
  
  /* Option Container */
  .option-container {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
  }
  
  /* Option Items */
  .option-item {
    cursor: pointer;
    text-align: center;
    color: #fff;
    font-size: 1.2rem;
    padding: 20px;
    border-radius: 8px;
    border: 2px solid transparent; /* Default border */
    transition: border 0.3s ease, background-color 0.3s ease;
  }
  
  .option-item.full-width-bg {
    background-color: #90ee904d;
    color: #443c3e;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 150px;
    flex: 1 1 100%; /* Full width by default */
    transition: border 0.3s ease
  }
  
  .option-item.full-width-bg:hover {
    border-bottom: .2em solid green;
    cursor: pointer;
  }

  .option-item.full-width-bg span:hover {
    cursor: pointer;
  }
  
 
  /* Responsive Layout */
  @media (min-width: 800px) {
    .option-item.full-width-bg {
      flex: 1 1 calc(50% - 15px); /* 50% width with gap adjustment */
    }
  }
  
