.goback {
    width: fit-content;
    user-select: none;
    padding: 1em;
    padding-left: 0;

    padding: 0.7em;
    margin: 0.6em;
    margin-left: 0;
    transition: background-color 0.2s, color 0.2s, transform 0.2s;
}

.goback:hover, .goback i:hover {
    cursor: pointer;
    color: #0c2148;
    transform: scale(1.05); 
    background-color: #E6F5FD;
}

.goback-button:hover {
    cursor: pointer;
}

@media screen and (max-width: 700px) {
    .goback {
        margin-top: -1.5em;
    }
    
  }