.innsamling {
  margin-left: 0em;
  background-color: white;
  margin: 0; 
}

/* Updated .innsamling to use as a flex container */
.innsamling-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.2em 0.8em;
  margin: 0;
  border-bottom: 1px solid #ccc; /* Optional: adds a line to separate the header */
  background-color: white;
  border-bottom: 0.05em solid lightgrey;
}

.innsamling .classPhoto {
  width: 100%;
  margin-top: 0.5em;
}

.innsamling .container {
  display: flex;
  flex-wrap: wrap; /* Allows items to wrap as needed */
}

.half.hvordan {
  flex: 1; /* This makes each .half.hvordan take up equal width */
  padding: 1em;
  text-align: left;
  background-color: #e6f5fd;
}

.half.hvordan img {
  width: 100%;
}


.right-content {
  flex-grow: 1;
  text-align: right;
}

.left-content {
  flex-grow: 1;
  text-align: left;
}

.innsamling-header .right-content img {
  max-width: 10em;
}

.innsamling-header .left-content img {
  max-width: 4.5em;
  margin-left: 1em;
}

.innsamling-left {
  flex-grow: 1;
}

.tabs-header {
  display: flex;
  justify-content: center;
  flex-grow: 10;
  margin: 0;
  padding: 0;
}

/* Example class for the right-side content */

/* Adjustments for smaller screens */
@media (max-width: 768px) {
  .innsamling-header {
    flex-direction: column;
  }
  
  .tabs-header, .right-content, .left-content {
    width: 100%;
    justify-content: center;
    padding-top: 0.5em;
  }
}



.innsamling-utlysning {
    width: 100%;
    user-select: none;
    text-align: center;
  }

  .innsamling u {
    border-bottom: 0.1em solid #007bff;
  }
  
  .tab-header {
    flex: 1;
    cursor: pointer;
    text-align: center;
    padding: 0em;
    background-color: #f0f0f0;
    border-bottom: 0.08em solid grey;
    border-radius: 0.5em;
    margin: 1em 1em;
    font-size: 0.8em;
  }

  .tab-header:first-child {
    margin-left: 0;
  }  
  
  .tab-header:last-child {
    margin-right: 0;
  }

  .tab-header h3 {
    cursor: pointer;
  }
  
  .tab-header.active {
    background-color: #e6f5fd;
  }
  
  .tab-content {
    width: 100%;
    border-top: none;
  }

  .all-records {
    min-height: 300px;
  }

  .record {
    background-color: rgba(211, 211, 211, 0.3);
    border-radius: 0.5em;
    padding: 1.5em;
    width: auto;
    font-size: 0.85em;
    display: inline-block;
    margin: 0.5em;
    max-width: 30em;
    text-align: left;
    min-width: 15em;
  }


.record p, .record b {
  line-height: 0.8;
}

.record p.beskrivelse {
  height: 3em;
  line-height: 1.5;
}


  .innsamling .container {
    display: flex;
    width: 100%;
  }

  
  .innsamling .half {
    width: 48%;
  }

  .half.step p {
    padding: 0;
  }

  .half.hvordan {
    padding: 1em;
    text-align: left;
    background-color: white;
  }

  .half.hvordan img {
    width: 100%;
  }

  .half.hvordan ul {
    margin: 0;
    padding: 0;
  }

  .half.hvordan h3 {
    margin-top: 0;
  }


  .half.betaling {
    padding: 1em;
    margin: 0.5em;
    margin-top: 1.5em;
    border-radius: 0.5em;
    text-align: left;
    background-color: #f0f9ff;
    padding-top: 0.5em;
    margin-bottom: 1.5em;
  }

  .half.betaling p {
    margin-bottom: 0;
    margin-top: 0.2em;
  }
  
  /* If you need to remove the padding and margin of ul and p explicitly */
.innsamling .half ul {
  line-height: 1.8;
  padding: 1em; /* Adds padding inside the list for better spacing */
  color: #333; /* Darker text color for contrast */
  list-style-position: inside; /* Ensures list markers are inside the padding area */
}

  
.job-post-form {
  display: flex;
  flex-wrap: wrap; /* Ensure it doesn't wrap to the next line */
  justify-content: space-between;
  margin-top: 1em;
  text-align: left;
}

.innsamling .step {
  flex: 1; /* This makes each step flexible but attempts to fill the space */
  min-width: calc(50% - 2em); /* Prevents steps from being too narrow */
  padding: 1.5em;
  margin: 0 1em; 
  border-radius: 1em;
}

.innsamling .step:first-child, .innsamling .step:nth-child(3) {
  background-color: #f0f9ff; /* Light blue */
  margin-right: 0.5em;
  margin-bottom: 1em; 
}

/* Step 2 Background */
.innsamling .step:nth-child(2), .innsamling .step:nth-child(4) {
  background-color: #f0f9ff; /* Light red */
  margin-left: 0.5em;
  margin-bottom: 1em; 
}

.job-post-form .information {
  margin: 0;
  margin-bottom: 1em;
}

.job-post-form .information .steg {
  font-size: 0.7em; /* Adjust if necessary */
  font-weight: lighter;
  user-select: none;
  background-color: #f0f9ff;
  color: #1ba0f2;
  padding: 0.5em; /* Adjust padding to ensure it's proportional */
  border-radius: 50%;
  width: 2em; /* Increase width */
  height: 2em; /* Increase height */
  display: inline-flex; /* Use flexbox to center content */
  align-items: center; /* Center content vertically */
  justify-content: center; /* Center content horizontally */
  margin: 0;
  border: 0.1em solid #1ba0f2;
  text-align: center;
  line-height: 1; /* Adjust line height for vertical alignment */
}

.job-post-form .information:first-of-type {
  margin: 0;
  margin-bottom: 1em;
  margin-top: 0;
}

.job-post-form .information p {
  margin-top: 0;
  font-weight: bold;
  padding: 0;
}

.job-post-form .form-group {
  margin-bottom: 20px;
}

.job-post-form .form-group label {
  display: block;
  margin-bottom: 5px;
}

.job-post-form .form-group input[type="text"],
.job-post-form .form-group input[type="email"],
.job-post-form .form-group input[type="tel"],
.job-post-form .form-group textarea {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.job-post-form .form-group textarea {
  max-height: 60px;
  resize: none;
  margin-top: 0;
}

/* Adjust the button styles */
.job-post-form button[type="submit"] {
  width: 100%; /* Make the button take up the full width */
  padding: 1em;
  margin-top: 20px;
  background-color: #1ba0f2;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 0.5em;
  font-size: 1em;
}

.job-post-form button[type="submit"]:hover {
  background-color: #0056b3;
}

/* Add styles for the checkboxes */
.job-post-form .form-group input[type="checkbox"] {
  margin-right: 10px; /* Space between checkbox and label */
}

.job-post-form .form-group label {
  display: inline; /* Align checkbox and label on the same line */
}

.job-post-form .consent {
  font-size: 0.9em;
}

.label-and-counter {
  display: flex;
  justify-content: space-between; /* Ensures items are justified to each end */
  align-items: center; /* Keeps items vertically centered */
  width: 100%; /* Ensures the container uses the full width */
  padding: 0;
}

.innsamling .counter {
  text-align: right;
  font-size: 0.7em; /* Adjusts the font size of the counter */
}

.greyBorder {
  text-align: center;
  border: 0.05em solid grey;  
  border-radius: 0.3em; 
  margin-top: 0.5em;
}

.epost-input {
  border: 0.1em solid grey;
  padding: 0.2em;
}

.success-message {
  margin-top: 1em;
  padding: 0.5em;
  background-color: lightgreen;
  color: green;
}

.error-message {
  margin-top: 1em;
  padding: 0.5em;
  background-color: rgba(240, 128, 128, 0.463);
  color: red;
}

.submit-stilling-btn {
  width: 100%;
  padding: 10px;
  margin: 0em 1em;
}


@media (max-width: 768px) {
  .article.innsamling {
    margin-left: 0;
  }

  .innsamling-utlysning,
  .tabs-header,
  .tab-content,
  .all-records,
  .record,
  .innsamling .container,
  .job-post-form,
  .job-post-form .form-group input[type="text"],
  .job-post-form .form-group input[type="email"],
  .job-post-form .form-group input[type="tel"],
  .job-post-form .form-group textarea {
    width: 100%; /* Ensure full width on smaller screens */
  }

  .innsamling-utlysning {
    padding: 0 0.8em;
  }

  .tabs-header,
  .tab-header {
    flex-direction: column; /* Stack tabs vertically */
    margin: 0.5em 0; /* Adjust margin for compact layout */
    padding: 0;
  }

  .tab-header.active {
    margin: 0.5em 0;
  }

  .innsamling .half,
  .innsamling .step {
    width: 100%; 
    margin: 0.5em 0;
  }

  .article.innsamling {
    margin: 0.2em;
  }

  .innsamling .container {
   flex-direction: column;
  }

  .innsamling .container .half.betaling {
    width: 95%;
    margin: auto;
    margin-bottom: 0.8em;
  }

  .record {
    max-width: none; /* Allow records to expand */
    min-width: 0; /* Remove minimum width constraint */
    margin-left: 0;
  }

  .all-records {
    border: 0;
  }

  .innsamling .greyBorder {
    border: 0;
  }

  .job-post-form {
    flex-direction: column; /* Stack form elements vertically */
  }

  .job-post-form .form-group {
    width: 100%; /* Full width for form inputs */
  }

  .job-post-form button[type="submit"] {
    padding: 0.5em; /* Adjust padding for smaller touch targets */
  }



  .innsamling .step:nth-child(2), .innsamling .step:nth-child(3), .innsamling .step:nth-child(4), .submit-stilling-btn {
    margin-left: 0;
  }

  /* .label-and-counter,
  .innsamling .counter,
  .greyBorder,
  .epost-input,
  .success-message {
    
  } */
}

.innsamling-navbar {
  width: 100%;
  background-color: #1ba0f2;
  text-align: center;
  color: white;
  font-size: 0.8em;
  padding: 0.2em;
}

.innsamling-navbar a {
  color: white;
}

.innsamling-navbar u {
  border-bottom: 0.1em solid white;
}

.innsamling-navbar a:hover {
  font-weight: bold;
}

.approve-btn, .deny-btn {
  cursor: pointer;
  margin-right: 10px;
  padding: 5px 10px;
  border: none;
  border-radius: 5px;
  color: white;
}

.approve-btn {
  margin-top: 1em;
  background-color: #4CAF50; /* Green */
  cursor: pointer;
}

.deny-btn {
  margin-top: 1em;
  background-color: #f44336; /* Red */
  cursor: pointer;
}

.denied-record {
  opacity: 0.5; /* Or any styling you prefer */
  background-color: #f0f0f0; /* Light grey background */opacity: 0.5; /* Or any styling you prefer */
  background-color: #f0f0f0; /* Light grey background */
}

.content-wrapper {
  padding: 0.3em;
  border-radius: 0.3em;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  margin: 0; 
  margin-bottom: 1em;
}

.event-description {
  margin-bottom: 15px; /* Space between paragraphs */
}

.action-links {
  color: #1ba0f2;
  font-size: 0.8em;
  display: flex; /* Use flex display to align items on the same line */
  flex-wrap: wrap; /* Allows items to wrap if necessary */
  gap: 10px; /* Adjust the space between the items */
  justify-content: center;
}

.action-links a {
  color: #1ba0f2; /* Bootstrap's default link color */
  text-decoration: none; /* Remove underline from links */
  display: inline-block; /* Keep each link inline */
}

.action-links a:hover {
  text-decoration: underline; /* Add underline on hover for links */
  font-weight: bold; /* Make the text bold on hover */
  cursor: pointer;
}


/* Custom link styles */
.download-link {
  border-bottom: 2px solid #28a745; /* Green underline */
}

.employer-link {
  border-bottom: 2px solid #dc3545; /* Red underline */
}

.parent-link {
  border-bottom: 2px solid #ffc107; /* Yellow underline */
}

.event-date {
  display: inline; /* Default display, adjust as necessary */
}

@media (max-width: 768px) {
  .event-date {
    display: block; /* Makes the date appear on its own line */
  }
  
  .left-content, .right-content {
    display: none;
  }

  .visually-hidden {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
  }
  
  

  /* Improve form input touch responsiveness */
  .job-post-form .form-group input[type="text"],
  .job-post-form .form-group input[type="email"],
  .job-post-form .form-group input[type="tel"],
  .job-post-form .form-group textarea {
    padding: 12px; /* Increase padding for easier interaction */
  }

  /* Increase button size for better touch interaction */
  .job-post-form button[type="submit"], .approve-btn, .deny-btn {
    padding: 10px 20px; /* Increase padding */
    font-size: 1em; /* Increase font size for readability */
  }



  /* Enhance visibility of critical interaction elements */
  .approve-btn, .deny-btn {
    font-size: 0.9em; /* Adjust font size for clarity */
    padding: 8px 16px; /* Adjust padding for a balanced look */
  }

  /* Adjust content container for better mobile readability */
  .content-wrapper {
    padding: 0.5em; /* Increase padding for better content spacing */
    margin: 0.5em 0; /* Adjust margin for consistency */
  }
  
  /* Additional tweaks for better mobile experience */
  .record, .all-records {
    padding: 0.5em; /* Adjust padding for content breathing space */
  }

  .tab-content {
    padding: 0;
  }

  /* Ensure all images within records are fully responsive */
  .record img {
    max-width: 100%; /* Ensure images do not overflow their container */
    height: auto; /* Maintain aspect ratio */
  }

  .record {
    padding: 1em;
  }

  .innsamling .container {
    flex-direction: column; /* Stack children vertically */
    padding: 0;
  }

  .hvordan h2 {
    padding: 0.2em;
  }

  .half.hvordan h3 {
    padding: 0 1em;
    margin-bottom: 0;
  }

  .half.hvordan p {
    padding: 1em;
  }

  /* Assuming the third and fourth .half.hvordan have IDs or specific classes for targeting */
  .third-hvordan {
    order: 3; /* This will be the default, but it's good to be explicit */
  }

  .fourth-hvordan {
    order: 2; /* Moves the fourth .half.hvordan before the third on mobile */
  }
}
