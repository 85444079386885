.all-students-page {
    padding: 20px;
  }
  
  .all-students-page h2 {
    text-align: left;
    margin-bottom: 20px;
  }
  
  .students-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
  }
  
  .students-table th,
  .students-table td {
    border: 1px solid #ddd;
    text-align: left;
    vertical-align: middle;
  }

  .warning-div p, .warning-div i {
    color: #d9534f;
    line-height: 1;
  }

  .fa-triangle-exclamation {
    color: #d9534f;
    /* font-size: 1.5em; */
  }
  
  .students-table th {
    background-color: #f4f4f4;
    font-weight: bold;
  }

  .students-table td {
    padding: 0 0.5em;
  }
  
  .students-table input[type="text"],
  .students-table input[type="number"] {
    width: 80%;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .students-table input[type="checkbox"] {
    transform: scale(1.2);
  }
  
  .students-table tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  
  .students-table tr:hover {
    background-color: #f1f1f1;
  }
  
  .student-history {
    text-align: left;
    margin: 0;
    padding: 0;
    list-style-type: none;
    font-size: 12px;
  }
  
  .student-history td {
    margin: 0;
    padding: 0;
    border:0;
    text-align: left;
    background-color: white;
    padding-right: 1em;
  }
  
  .promote-button {
    background-color: #4caf50;
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    border-radius: 5px;
    margin-left: 0.5em;
  }
  
  .promote-button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  
  .promote-button:hover:not(:disabled) {
    background-color: #388e3c;
  }
  
  .editstudent-popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .popup-content {
    background: white;
    padding: 20px;
    border-radius: 8px;
    width: 400px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  }
  
  .popup-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }

  .popup-save-button {
    background-color: #4caf50;
    color: white;
    border: none;
    padding: 0.4em 0.8em;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1em;
    font-weight: bold;
  }
  
  .popup-cancel-button {
    background-color: #f44336;
    color: white;
    border: none;
    padding: 0.4em 0.8em;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
  }

  .add-student-button {
    border: none;
    padding: 0.4em 0.8em;
    border-radius: 5px;
    cursor: pointer;
    font-size: 0.9em;
    border-bottom: 0.2em solid #4caf50;
  }

  .allstudents-student-image {
    max-width: 150px;
    border-radius: 1.5em;
    padding: 0.5em;
  }

  .margin-left-small {
    margin-left: 1em;
  }
