.remarks-container {
    padding: 20px;
}

.remarks-table table {
    width: 100%;
    table-layout: fixed; 
    border-collapse: collapse;
  }

.remarks-table th,
.remarks-table td {
    border: 1px solid #ccc;
    padding: 10px;
    text-align: left;
}

.remarks-table th {
    background-color: #E6F5FD;
}

.remark-info span {
    display: block;
    margin-bottom: 0.6em;
}

.remarks-table tr:nth-child(even) {
    background-color: #f2f2f2;
}

.remarks-table tr:hover {
    background-color: #e6f7ff;
}

.remarks-table b {
    font-size: 0.9em;
}

.remarks-view {
    margin-top: 1em;
}

.remark-button {
    border: none;
    padding: 0.4em 0.8em;
    border-radius: 5px;
    cursor: pointer;
    color: #333;
    border-bottom: 0.2em solid #4caf50;
}

.remark-button.add {
    font-size: 0.9em;
}

.remark-button.blue {
    border-bottom: 0.2em solid #1ba0f2;
}

.remark-button.red {
    border-bottom: 0.2em solid darkred;
    font-size: 0.7em;
}

.remarks-textarea {
    width: 350px;
    border-radius: 4px;
    border: 1px solid #ccc;
    padding: 10px;
    font-size: 14px;
    resize: none;
  }

.category-description {
    font-size: 0.8em;
    padding-top: 0.5em;
    padding-bottom: 1em;
    color: #007bff;
}

.category-description i {
    color: #007bff;
}
