/* Styles for the staff-popup container */
.staff-popup-container {
    width: 40em;
    background: #E6F5FD;
    border-radius: 10px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 20px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
    z-index: 1000;
  }
  
  .staff-popup-container h3 {
    text-align: center;
    font-size: 1.8em;
    margin-bottom: 1em;
    color: #333;
  }
  
  /* Styles for the staff-popup table */
  .staff-popup-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .staff-popup-table td {
    padding: 0.2em;
    font-size: 1em;
    color: #333;
    vertical-align: middle;
  }
  
  .staff-popup-table td:first-child {
    font-weight: bold;
    width: 30%;
    text-align: right;
    padding-right: 15px;
  }
  
  .staff-popup-table td:last-child {
    text-align: left;
  }
  
  .staff-popup-input,
  .staff-popup-select {
    width: 100%;
    padding: 8px;
    margin: 5px 0;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 1em;
  }
  
  .staff-popup-input[type="file"] {
    padding: 5px;
    border: none;
    background-color: transparent;
  }
  
  /* Styles for staff-popup buttons */
  .staff-popup-button {
    width: 100%;
    padding: 10px;
    background-color: #1ba0f2;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1.1em;
    margin-top: 10px;
  }
  
  .staff-popup-button:hover {
    background-color: #1283c8;
  }
  
  .staff-popup-close-button {
    background-color: #ccc;
    color: #333;
  }
  
  .staff-popup-close-button:hover {
    background-color: #999;
  }
  
  /* Optional media query for smaller screens */
  @media (max-width: 700px) {
    .staff-popup-container {
      width: 90%;
    }
  
    .staff-popup-table td:first-child {
      text-align: left;
      padding-right: 0;
    }
  
    .staff-popup-table {
      font-size: 0.9em;
    }

    .staff-popup-container {
        width: 100%;
        top: calc(50%);
        left: calc(50%);
        padding: 2%;
    }
  }


/* Style for cards of inactive members */
.card.inactive {
    border: 1px solid orange; /* Highlight inactive users for admins */
  }

  
  