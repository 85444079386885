/* General Table Styling */
.students-table-parent {
    overflow-x: auto;
    padding: 1em;
}

.students-table {
    width: 100%;
    table-layout: fixed;
    border-collapse: collapse;
    user-select: none;
    background-color: #ffffff;
}

.students-table th,
.students-table td {
    border: 1px solid #ccc;
    padding: 12px;
    text-align: center;
    font-size: 14px;
}

.students-table th {
    background-color: #E6F5FD;
    font-weight: bold;
    color: #333;
}

.students-table tr {
    background-color: #fff; /* Remove alternating colors */
}

.students-table tr:hover {
    background-color: #e6f7ff;
    transition: background-color 0.2s ease-in-out;
}

/* Highlighting Specific Rows */
.highlight-positive {
    background-color: #e6ffed !important; /* Light green for positive highlights */
}

.highlight-negative {
    background-color: #ffe6e6 !important; /* Light red for negative highlights */
}

/* Button Styling */
.students-table button {
    background-color: #4caf50;
    color: white;
    border: none;
    padding: 8px 16px;
    cursor: pointer;
    border-radius: 4px;
    font-size: 12px;
}

.students-table button:hover {
    background-color: #45a049;
}

.students-table button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

.students-table button.remove {
    background-color: #ff4d4f;
}

.students-table button.remove:hover {
    background-color: #d43533;
}

/* Sort Buttons Styling */
.sort-buttons {
    margin: 1em 0;
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
}

.sort-buttons button {
    background-color: #f0f0f0;
    border: 1px solid #ccc;
    padding: 8px 12px;
    cursor: pointer;
    border-radius: 4px;
    font-size: 14px;
    transition: background-color 0.3s ease;
}

.sort-buttons button:hover {
    background-color: #e0e0e0;
}

.sort-buttons button.active-sort {
    font-weight: bold;
    border-color: grey;
}
