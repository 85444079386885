

.section.border {
    border: 1px solid lightgrey;
}

.section-description, .section-sectionDescription {
    margin-top: 1em;
    line-height: 1.7em;
}


.quill-input {
    width: 100%;
    min-width: 100%;
    margin-bottom: 80px;
}

.quill-input .ql-container {
    width: 100%;
    height: auto !important;
}

.quill-input .ql-editor {
    min-height: 150px; 
    height: auto;
    overflow-y: hidden;
    word-wrap: break-word;
    white-space: pre-wrap;
    width: 100%;
}

.section .quill-input {
    margin-bottom: 0;
    /* height: 12em; */
    padding-right: 1em;
    width: 100% !important;
}



.section .flex-image-text img {
    min-width: 50%;
}

.section .flex-image-text > div {
    min-width: 50%;
    margin-right: 0;
    padding-right: 0;
}

.section .titleInput {
    width: 65%;
}

.header-h2 {
    min-width: 100%;
}

.section .headerInput {
    min-width: 65%;
}

.section .dateInput {
    padding-top: 0;
    margin-top: 0;
    border-radius: 0;
    width: 90%;
}


.section .file-upload-button.section {
    width: 35%;
    font-size: 0.95em;
}

.section .flex-image-text div .editMode {
    padding-right: 0;
}

.section .file-upload-button.header {
    text-align: center;
    margin: 0;
    padding: 0.85em 0.4em;
    width: 30%;
    font-size: 0.95em;
}


.section .year {
    width: 4em;
}

.section .h3-file-upload {
    width: 100%;
}

.section  .section-h3 {
    margin-bottom: -0.4em;
}

.confirmation-message, .error-message-2 {
    position: fixed;
    bottom: 1.2em; /* Positioned at the bottom */
    right: 1.2em; /* Positioned at the right */
    width: 82%; /* Adjusted width for better readability */
    max-width: 500px; /* Limiting maximum width for better responsiveness */
    background-color: #4caf50;
    color: white;
    padding: 15px; /* Increased padding for better spacing */
    font-size: 1.2em; /* Slightly increased font size for emphasis */
    text-align: center;
    border-radius: 8px; /* Slight border radius for softer edges */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Added shadow for depth */
    z-index: 999;
    user-select: none;
}

.confirmation-message i, .error-message-2 i {
    color: white;
}

.error-message-2 {
    background-color: rgb(255, 113, 113);
}

.lessSize {
    font-size: 0.8em;
}


.section.unsaved {
    border: 0.1em solid rgba(255, 166, 0, 0.5);
    border-radius: 0.3em;
  }

  
