.current-sponsorships-table {
    width: 100%;
    table-layout: fixed;
    border-collapse: collapse;
    user-select: none;
}

.current-sponsorships-table th,
.current-sponsorships-table td {
    border: 1px solid #ccc;
    padding: 10px;
    text-align: center;
}

.current-sponsorships-table td:nth-child(1),
.current-sponsorships-table th:nth-child(1) {
    text-align: left;
    width: 250px;
}

.current-sponsorships-table td:nth-child(2),
.current-sponsorships-table th:nth-child(2) {
    text-align: left;
}

.current-sponsorships-table td:nth-child(3),
.current-sponsorships-table th:nth-child(3){
    text-align: left;
}

.currency-amount-container {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
  }

.current-sponsorships-table th {
    background-color: #E6F5FD;
}

.current-sponsorships-table tr:nth-child(even) {
    background-color: #f2f2f2;
}

.current-sponsorships-table tr:hover {
    background-color: #e6f7ff;
}

.current-sponsorships-table input:not([type="date"]) {
    width: 80px;
}

.edited-row {
    border: 2px solid rgb(255, 199, 94);
  }


.assign-sponsorship-parent {
    gap: 1em;
    background-color: #f2f2f2;
    padding: 0.5em;
    left: 0;
    border: 1px solid #ccc;
}

.assign-sponsorship-parent div {
    display: flex;
    align-items: center;
    margin-bottom: 0.5em;
    gap: 0.3em;
}

.assign-sponsorship-parent div input:not([type="date"]) {
    width: 80px;
}



.manage-users-parent {
    gap: 1em;
    background-color: #f2f2f2;
    padding: 0.5em;
    left: 0;
    border: 1px solid #ccc;
}

.manage-users-parent div {
    display: flex;
    align-items: center;
    margin: 0.2em 0;
    gap: 0.3em;
}

.manage-users-parent div select:first-of-type {
    margin-right: 4em;
}


.edit-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-content {
  background: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}
