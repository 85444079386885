
.grid-container {
    display: grid;
    grid-template-columns: 1fr 1fr; /* Two equal columns */
    grid-template-rows: auto auto; /* Two rows, height set automatically by content */
    gap: 10px; /* Space between boxes */
    padding: 10px; /* Padding around the grid */
    box-sizing: border-box;
    min-height: 85vh;
    font-size: 0.95em;
  }


.grid-container.sponsorpage {
  display: grid;
  grid-template-columns: 1fr 1fr; /* Two equal columns */
  grid-template-rows: auto auto; /* Two rows, height set automatically by content */
  gap: 10px 20px; /* Space between boxes */
  padding: 0px; /* Padding around the grid */
  padding-bottom: 10px;
  box-sizing: border-box;
  min-height: 0;
  font-size: 0.95em;
}

.sponsor-navigation-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.sponsor-info-container {
  display: flex;
  align-items: center; /* Align items vertically */
}


.change-child-container {
  display: flex;
  align-items: right;
}

/* Media query for mobile responsiveness */
@media (max-width: 1000px) {
  .sponsor-navigation-container {
    flex-direction: column; /* Stacks items vertically on small screens */
    align-items: stretch; /* Ensures items stretch to full width */
  }

  .sponsor-info-container {
    margin-bottom: 0.5em; /* Adds space between sponsor info and navigation */
  }

  .change-child-container {
    width: 100%; /* Takes full width on mobile */
  }

  .change-child-container .feature-box {
    width: 50%; /* Each item takes 50% of the container width */
    text-align: center;
    font-size: 0.9em;
  }
}



.mobile-only {
  display: block; /* Show element */
}

.computer-only {
  display: none; /* Hide element */
}

@media (min-width: 799px) {
  .mobile-only {
    display: none; /* Hide element on computer screens */
  }

  .computer-only {
    display: block; /* Show element on computer screens */
  }
}



  
  .left-column {
    grid-column: 1 / 2; /* Takes up the first column */
    grid-row: 1 / -1; /* Spans from the first to the last row */
    display: grid;
    grid-template-columns: 1fr 1fr; /* Creates two columns */
    grid-template-rows: 1fr 1fr; /* Creates two rows */
    gap: 10px; /* Space between boxes */
    transition: opacity 0.5s ease;
  }
  
  .right-column {
    grid-column: 2 / 3; /* Takes up the second column */
    grid-row: 1 / -1; /* Spans from the first to the last row */
    /* Keep your existing flex styles if you want */
  }

  .grid-item {
    background-color: white; 
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    border: 1px solid #ddd; 
    padding: 0.2em 1em;
    box-sizing: border-box;
    height: 100%;
    text-align: left;
    border-radius: 0.3em;
  }

  .grid-item.sponsorpage {
    padding-bottom: 1em;
    border: 0; 
    box-shadow: var(--timelineCardBoxShadow, 0 0.1em 0.2em 0 rgba(0, 0, 0, .12), 0 0.1em 0.2em 0 rgba(0, 0, 0, .24));
  }  

  
  .grid-item h3 {
    margin-bottom: 0.4em;
  }

  .grid-item p {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 0.95em;
  }
  
  .image-container {
    width: 100%; /* Height of the image container */
    aspect-ratio: 1 / 1; /* Aspect ratio of the image */
    overflow: hidden; /* Hide overflowing content */
  }
  
  .student-image {
    width: 100%; /* Make the image fill the container */
    height: auto; /* Maintain aspect ratio */
  }
  
  .text-container {
    margin: 0 0.3em;
    text-align: left;
  }
  
  .students-container {
    display: flex;
    flex-wrap: wrap; /* Allow flex items to wrap to next line */
    justify-content: flex-start; /* Distribute items evenly */
  }

  .text-container.student-overview {
    line-height: 1em;
  }
  
  .student-box {
    border-radius: 5px;
    /* font-size: 0.9em; */
    margin: 0.4em;
    flex: 1 1 calc(33.33% - 0.8em); /* Default to 3 boxes */
    box-shadow: 0 0.25em 1em rgba(0, 0, 0, 0.1);
  }

  .student-name {
    padding-top: 0.6em;
  }

  .start-sponsorship {
    margin-bottom: 0;
    padding: 0.5em;
  }

  .start-sponsorship i {
    color: white;
  }

  .sensitive {
    background-color: rgb(251, 246, 246);
    padding: 0.5em 1em;
    margin-top: 1em;
    margin-bottom: 1em;
    border-radius: 5px;
  }
  
  /* Medium screens: 2 boxes */
  @media (max-width: 768px) {
    .student-box {
      flex: 1 1 calc(50% - 0.8em);
      border: 0;
      border-bottom: 1px solid #ccc;
    }

    .start-sponsorship {
      width: 100%;
    }
  }
  
  /* Small screens: 1 box */
  @media (max-width: 480px) {
    .student-box {
      flex: 1 1 100%;
      max-width: none;
      margin-bottom: 1em;
      margin: 0.5em 0;
    }
  }

  .goback:hover {
    cursor: pointer;
  }
  
  
  .student-box p {
    margin: 5px 0;
  }
  
  .student-box button {
    padding: 5px 10px;
    font-size: 0.9em;
  }
  
  .reportform textarea {
    border: 0.1em solid lightblue;
    margin-bottom: 1em;
  }


  .student-container-child {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
  
  .student-details-child {
    flex: 1;
    margin-right: 1em;
    min-width: 200px; /* Ensure some minimum width for smaller screens */
    margin-left: 1em;
  }
  
  .image-container-child {
    flex: 1;
  }
  
  .student-image-child {
    max-width: 100%;
    height: auto;
  }
  
  @media (max-width: 600px) {
    .student-container-child {
      flex-direction: column;
      align-items: flex-start;
    }
  
    .student-details-child,
    .image-container-child {
      margin-right: 0;
      margin-left: 0;
      width: 100%;
    }
  
    .student-image-child {
      width: 100%;
    }
  }
  