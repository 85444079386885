.vipps-recurring-container p {
    font-size: 16px;
    text-align: left;
}

.vipps-recurring-container input {
    border-bottom: 0.2em solid #FF5B26;
    border-radius: 0.3em;
}

.vipps-recurring-container button {
    font-size: 1em;
    left: 0;
    margin: left;
}
