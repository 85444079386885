/* Flexbox layout for the navigation buttons (now tabs) */
.intranet-navigation {
    display: flex;
    justify-content: space-between;
    width: 100%;
    border-bottom: 2px solid #ddd; /* Bottom border for the entire tab area */
    max-width: 1600px;
    margin: auto;
    margin-top: 1.5em;
    overflow-x: auto;
    white-space: nowrap;
    padding: 0 1.5em;
  }

  .intranet-navigation::-webkit-scrollbar {
    height: 0.3em; /* Adjust scrollbar height */
}

  
  /* Style for individual tabs */
  .nav-button {
    flex: 1 1 auto;
    padding: 10px 20px;
    background-color: rgb(216, 245, 255); /* Make the background transparent */
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.1);
    color: #007bff;
    border: none;
    border-bottom: 3px solid transparent; /* Only show active tab's bottom border */
    margin: 0 10px; /* Spacing between tabs */
    cursor: pointer;
    font-size: 16px;
    text-align: center;
    transition: all 0.3s ease;
    font-weight: bold;
    border-radius: 0.3em 0.3em 0 0;
  }

  .nav-button i {
    color: #007bff;
  }

  .nav-button i:hover {
    cursor: pointer;
  }
  
  /* Active tab styling */
  .nav-button.active {
    border-bottom: 3px solid #007bff; /* Highlight the active tab with bottom border */
    color: #0056b3;
  }
  
  /* Hover effect for tabs */
  .nav-button:hover {
    color: #0056b3;
  }
  
  /* Remove margin on the first and last tab for alignment */
  .nav-button:first-child {
    margin-left: 0;
  }
  
  .nav-button:last-child {
    margin-right: 0;
  }
  
  /* Content styling */
  .intranet-content {
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 1600px;
    margin: auto;
  }

  .request-count {
    background-color: red;
    color: white;
    border-radius: 50%;
    padding: 2px 6px;
    font-size: 12px;
    margin-left: 5px;
  }

  .request-count.navbar, .request-count.navbar i {
    background-color: #1ba0f2;
    color: white;
    font-weight: bold;

    border-radius: 0.3em;
  }

  .request-count.navbar i {
    border: 0;
  }


  .request-count:hover, .request-count.navbar i {
    cursor: pointer;
  }

  .alert-message {
    background-color: rgb(255, 231, 235);
    color: red;
    padding: 15px;
    text-align: center;
    font-weight: bold;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 9999;
    margin-top: 0;
  }
  
  .admin-alert textarea {
    width: 80%;
    height: 50px;
  }
  

  

  @media (max-width: 768px) {
    .intranet-navigation {
      flex-direction: column; /* Stack navigation buttons */
      align-items: center;
    }
  
    .nav-button {
      width: 90%; /* Full width for buttons */
      font-size: 16px;
      margin-bottom: 0.5em;
      text-align: left;
    }
  
    .intranet-content {
      padding: 10px;
      font-size: 14px;
    }

    .nav-button:last-child {
    margin: auto;
    }


  }


  
 
  
  /* Tablet-friendly layout */
  @media (min-width: 768px) and (max-width: 1024px) {
    .nav-button {
      font-size: 14px;
    }
  
    .intranet-content {
      padding: 15px;
    }
  }
  
  