.admin-table {
    width: 100%;
    table-layout: fixed;
    border-collapse: collapse;
    user-select: none;
  }
  
  .admin-table th,
  .admon-table td {
    border: 1px solid #ccc;
    padding: 10px;
    text-align: center;
  }
  
  .admin-table tr:nth-child(even) {
    background-color: #f2f2f2;
  }
  
  .admin-table tr:hover {
    background-color: #e6f7ff;
  }

  .admin-access-table td {
    text-align: center;
  }

  .admin-access-table th:nth-child(1), .admin-access-table th:nth-child(2),
  .admin-access-table td:nth-child(1), .admin-access-table td:nth-child(2) {
    min-width: 10em;
    text-align: left;
  }
  