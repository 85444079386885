.modal {
    position: fixed;
    z-index: 1000;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modal-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 4px;
    width: 90%;
    max-width: 500px;
    position: relative;
  }
  
  .close {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 24px;
    font-weight: bold;
    color: #aaa;
  }
  
  .close:hover,
  .close:focus {
    color: #000;
    cursor: pointer;
  }
  
  .document-categories-container {
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;
  }
  
  
  .document-category {
    /* border: 1px solid #ddd; */
    padding: 10px;
    border-radius: 4px;
  }
  
  .document-card {
    margin-top: 10px;
    padding: 5px;
    border-bottom: 1px solid #eee;
  }
  
  /* Add these styles to Documents.css for a single-line layout */
.document-card .document-view-row,
.document-card .document-edit-row {
  display: flex;
  align-items: center;
  gap: 10px;
  flex-wrap: nowrap;
}

.document-card .document-title {
  flex: 2;
  min-width: 100px;
}

.document-card .document-description {
  flex: 3;
  min-width: 150px;
}

.document-card .document-actions {
  flex: 0;
  white-space: nowrap;
}

.documents-header {
    margin-bottom: 0;
}

.noMarginLeft {
  margin-left: -0.75em;
}

@media (min-width: 800px) {
  .document-categories-container {
    grid-template-columns: 1fr 1fr;
  }
  
}

@media (max-width: 800px) {
  .document-card .document-title {
    flex: 1;
    min-width: 100px;
  }
  
  .document-card .document-description {
    flex: 1;
    min-width: 100px;
  }

  .noMarginLeft {
    margin-left: 0em;
  }
}
